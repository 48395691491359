@import "../default-config.scss";

/*-----------------------------------------------------------------------------*/
//Buttons
button.md-raised.md-primary.md-button:not([disabled]):hover {
	background-color: $primary-color-fade;
}

button.md-raised.md-warn.md-button:not([disabled]):hover {
	background-color: rgba(137, 0, 7, 0.5);
}

button.md-raised.md-info.md-button {
	background-color: $blue-color;
	color: white;
}

button.md-raised.md-info.md-button[disabled] {
	background: #c5c5c587;
}

button.md-raised.md-info.md-button:not([disabled]):hover {
	background-color: $blue-color-fade;
}

button.md-raised.md-alert.md-button {
	background-color: $orange-color;
	color: white;
}

button.md-raised.primary-button.md-button {
	color:  $primary-color;
	.material-icons {
		color: $primary-color;
	}
}

button.md-raised.lightest-bg.md-button {
	background-color: $primary-lightest;
}

button.md-raised.md-alert.md-button[disabled] {
	background: #c5c5c587;
}

button.md-raised.md-alert.md-button:not([disabled]):hover {
	background-color: rgba(233, 124, 0, 0.5);
}

button.md-raised.md-alert.md-button .material-icons {
	color: white;
}

button.md-raised.custom-btn-secondary {
	background-color: $secondary-color;
	color: white;

	& .material-icons {
		color: white;
	}
	&:not([disabled]):hover {
		background-color: $secondary-color-fade;
	}
}

//File input buttons
.lf-ng-md-file-input-button.md-raised.md-primary.md-button:not([disabled]):hover {
	background-color: $primary-color-fade;
}

.lf-ng-md-file-input-button.md-raised.md-button:not([disabled]):hover {
	background-color: rgba(158, 158, 158, 0.2);
}

.lf-ng-md-file-input-thumbnails .md-raised.md-button:not([disabled]):hover {
	background-color: rgba(158, 158, 158, 0.2);
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Top nav bar
.top-nav-bar-container {
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
	z-index: 4;
	overflow: hidden;

	.top-nav-bar {
		position: relative;
		width: 100%;
		min-height: 48px;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

		.logo {
			position: absolute;
			left: calc(50% - 84px); //half logo img width

			img {
				padding: 5px;
				height: 36px;
				width: 168px;
				margin-left: 0px;
				margin-top: 5px;
			}
		}

		.md-toolbar-tools {
			height: 48px;
			padding: 0px;
		}

		.md-button {
			margin: 0;
			height: 100%;
			line-height: 14px;
			padding-left: 0;
			padding-right: 0;
			min-width: 60px;
			border-radius: 0px;
		}

		.information-message-info,
		.information-message-warning,
		.information-message-error,
		.information-message-error-strong {
			padding: 8px 20px 8px 20px;
			font-size: 16px;

			.icon-wrapper {
				display: flex;

				em {
					font-size: 19px;
				}
			}
		}
	}

	.second-nav-bar {
		#toggleButton {
			padding-left: 16px;
			padding-right: 10px;

			.second-nav-bar-user {
				font-size: 12px;
				font-weight: bold;
				padding-top: 2px;
				text-align: right;
				padding-right: 10px;
			}

			.second-nav-bar-client {
				font-size: 10px;
				padding-top: 2px;
				text-align: right;
				padding-right: 10px;
			}
		}
	}
}
vgr-page .ius-natura {
	md-toolbar.top-nav-bar {
		background-color: $ius-natura-color;

		md-toolbar {
			background-color: $ius-natura-color;
		}
	}
	.md-sidenav {
		background-color: $ius-natura-color;
	}
	menu-right md-content.default-bg {
		background-color: $ius-natura-color;
	}
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Scrollbar
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

::-webkit-scrollbar-track {
	background-color: $light-grey;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: $gray-color;
}

::-webkit-scrollbar-thumb:hover {
	background-color: $primary-color;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Tabs
md-tabs.md-default-theme .md-tab,
md-tabs .md-tab {
	color: #5c5c5c;
}

md-tabs.md-default-theme .md-tab[disabled],
md-tabs .md-tab[disabled],
md-tabs.md-default-theme .md-tab[disabled] md-icon,
md-tabs .md-tab[disabled] md-icon {
	color: #cccccc;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Dashboard landing page
.dashboard-card-title-container {
	max-height: 70px;
}

.dashboard-card-title {
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 0.01em;
	line-height: 24px;
	padding-left: 10px;
}

.charts-export-button {
	button.md-raised.md-button {
		max-height: 50px;
		max-width: 50px;
		min-width: 32px;

		.material-icons {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			font-size: 21px;
			margin: 0;
		}
	}

	button.md-raised.md-button:hover {
		background-color: #9b9b9b;

		.material-icons {
			color: white;
		}
	}
}

.dashboard-pendency-item:hover {
	background-color: $light-grey;
}

.dashboard-pendency-item > span {
	padding: 0;
}

.dashboard-card-title.dashboard-money-text {
	font-size: 40px;
}

.dashboard-money-percent {
	margin-left: 10px;
	padding-top: 10px;
}

.expense-management-legend {
	width: 20px;
	height: 20px;
}

.expense-summary-sizing {
	width: 100%;
	height: 300px;
}

.expense-details-chart-sizing canvas {
	max-height: 300px;
	max-width: 300px;
}

.expense-filter > md-input-container {
	margin-bottom: 0;
}

.maintenance-home-card{
	background-color: $light-yellow;
	padding: 16px;
	font-weight: 700;
	font-size: 14px;
	text-align: center;
}

.dashboard-shortcut {
	.dashboard-shortcut-card-title {
		color: $gray-color;
		font-size: 14px;
		letter-spacing: 0.01em;
		line-height: 24px;
		padding-left: 10px;
		margin-bottom: 5px;
	}

	.md-button {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.05);
		border: 1px solid rgba(155, 155, 155, 0.2);
		padding-bottom: 6px;
		padding-top: 6px;
		font-size: 20px;
		width: 360px;
		height: 140px;
	}

	md-icon {
		font-size: 34px;
		margin-top: 25px;
		margin-bottom: 10px;
		height: 34px;
		width: 34px;
	}
}

.opportunities-number {
	align-items: center;
	display: flex;
	font-size: 35px;
	gap: 0.2vw;
	justify-content: center;
}

.opportunities-number .material-icons {
	margin: 0;
}

.opportunities-text {
	word-break: normal;
	hyphens: auto;
}

.opportunities-text em {
	font-size: 11px;
}

.opportunities-button {
	font-size: 13px;
	line-height: 2;
	max-width: 200px;
	white-space: normal;
	width: 100%;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Toolbar
md-toolbar.md-table-toolbar input {
	width: 100%;
	margin: 0;
	border: none;
	color: rgba(0, 0, 0, 0.87);
}

md-toolbar.md-table-toolbar input:focus {
	outline: none;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Button toggle for colapsing sections (used in reports and menu)
.md-button-toggle .md-toggle-icon.toggled {
	transform: rotateZ(180deg);
	-webkit-transform: rotateZ(180deg);
}

.md-button-toggle .md-toggle-icon {
	background-size: 100% auto;
	display: inline-block;
	margin: auto 0 auto auto;
	width: 15px;
	transition: transform 0.3s ease-in-out;
	-webkit-transition: -webkit-transform 0.3s ease-in-out;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Hommel diamond
hommel-diamond {
	background-image: url("/img/diamantePericulosidade.GIF");
	height: 203px;
	width: 213px;
}

.hommel-diamond-text {
	text-align: center;
	vertical-align: center;
	font-size: 300%;
	font-weight: bold;
}

.hommel-diamond-form {
	label {
		margin-left: 5px;
	}

	.background-blue {
		background-color: $blue-color;

		label {
			color: white;
		}
	}

	.background-red {
		background-color: $danger-color;

		label {
			color: white;
		}
	}

	.background-yellow {
		background-color: $attention-color;
	}

	.background-white {
		border: 1px solid black;
	}

	md-select {
		background-color: white;
	}
}

/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Information message
information-message.with-margin .information-message {
	margin: 16px;
}

.information-message {
	border: 1px solid;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	padding: 5px;
	text-align: center;

	md-icon {
		font-size: 20px;
	}
}

.information-message-success {
	border-color: $strong-green;
	color: $dark-grey;
	background-color: $light-green;

	md-icon,
	span {
		color: $dark-grey;
	}
}

.information-message-warning {
	border-color: $orange-color;
	color: $orange-color;
	background-color: $light-yellow;

	md-icon,
	span {
		color: $orange-color;
	}
}

.information-message-error {
	border-color: $danger-color;
	color: $danger-color;
	background-color: $light-red;

	md-icon,
	span {
		color: $danger-color;
	}
}

.information-message-error-strong {
	border-color: $danger-color;
	color: #ffffff;
	background-color: $danger-color;

	md-icon,
	span {
		color: #ffffff;
	}
}

.information-message-info {
	border-color: $blue-color;
	color: $dark-grey;
	background-color: $light-blue;

	md-icon,
	span {
		color: $dark-grey;
	}
}

.information-message-accent {
	border-color: $secondary-color;
	color: $secondary-color;
	background-color: #F9F3F7;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Borrow from bootstrap
html {
	font-size: 10px;
}

body {
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
}

fieldset {
	border: 0;
	margin: 0;
	min-width: 0;
	padding: 0;
}

legend {
	padding: 0;
	width: 100%;
	margin-bottom: 20px;
	font-size: 21px;
	line-height: inherit;
	border-bottom: 1px solid #e5e5e5;
}

legend,
pre {
	display: block;
	color: #333;
}

.text-center {
	text-align: center;
}

*,
:after,
:before {
	box-sizing: border-box;
}

table {
	background-color: transparent;
	border-collapse: collapse;
}

p {
	margin: 0 0 10px;
}

[role="button"] {
	cursor: pointer;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Steps wizard
.step-wizard-container {
	.step-box {
		outline: none;
		margin: 0 16px;
	}

	.step-box:not(:last-child):after {
		border-top: 0.1rem solid black;
		content: "";
		display: inline-block;
		transform: translate(76px, -5.7rem);
		width: 7rem;
	}

	.number-circle {
		border: 2px solid;
		border-radius: 50%;
		font-size: 14px;
		font-weight: bold;
		height: 48px;
		padding: 14px;
		width: 48px;
		text-align: center;
	}

	.number-circle.blue {
		background-color: $light-blue;
		border-color: $light-blue;
		color: $dark-grey;
	}

	.number-circle.blue.active {
		border-color: $blue-color;
	}

	.is-disabled .number-circle {
		background-color: $light-grey;
		border-color: $light-grey;
		color: grey;
	}

	.is-disabled .step-title {
		opacity: 0.6;
	}

	.step-title {
		font-size: 12px;
		min-width: 120px;
		text-align: center;
	}

	.step-title.highlighted {
		font-weight: 500;
	}
}

/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Supplier list
.filters-button-active {
	color: $primary-color;
}

.supplier-filter-container {
	.filters-title {
		font-weight: bold;
		font-size: 12px;
	}

	.supplier-filter-filter input {
		border-radius: 5px;
		border: 1px solid #d0d0d0;
		font-size: 12px;
	}
}

.bottom-left-filters {
	height: 48px;
	background-color: white;
	padding: 0px;

	.supplier-filter-type {
		color: $gray-color;
		line-height: normal;
		text-transform: none;
		text-align: left;
		padding-left: 6%;
	}

	.supplier-filter-type.supplier-filter-type-active {
		background-color: $primary-color;
		color: white;
	}

	.supplier-filter-type > div > span {
		margin-top: 14px;
	}

	.supplier-filter-type-loading {
		margin-right: 9px;
	}
}

.activate-tooltip {
	margin-left: 5px; 
	margin-bottom: 15px;
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Goals
.goal-edit-header {
	.block-title-text {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.block-label-text {
		font-weight: bold;
		margin-right: 5px;
	}

	.goal-edit-header-form {
		max-width: 320px;

		button {
			width: 125px;

			span {
				font-size: 15px;
			}
		}

		md-icon {
			height: 18px;
			margin-right: 5px;
		}
	}
}

.goal-edit-input {
	margin-top: 20px;

	.block-title-text {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//Reports
.custom-reports-conclusion-image {
	margin-bottom: 40px;

	span {
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
		color: $primary-color;
	}
}
/*-----------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------*/
//General
.title-line {
	width: 36px;
	height: 2px;
	border: solid 1px $primary-color;
}

.warning-ico {
	md-icon,
	i,
	em {
		color: $danger-color;
	}

	md-icon {
		margin-top: 8px;
	}
}

.content-nav-bar {
	.content-buttons {
		button.button-refresh.md-raised.md-button {
			min-width: 32px;

			.material-icons {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				font-size: 21px;
				margin: 0;
			}
		}

		button.button-refresh.md-raised.md-button:hover {
			background-color: #9b9b9b;

			.material-icons {
				color: white;
			}
		}
	}

	.search-bar {
		.material-icons.content-nav-bar-show-filters {
			margin-left: 0px;
			height: 32px;
			line-height: 33px;
			text-align: center;
			width: 32px;
		}

		.material-icons.content-nav-bar-show-filters:hover {
			background-color: #9b9b9b;
			color: white;
		}
	}
}

.money-positive {
	color: green;

	md-icon {
		color: green;
	}
}

.money-negative {
	color: $danger-color;

	md-icon {
		color: $danger-color;
	}
}

md-toolbar.search-bar {
	background-color: white;

	md-icon {
		color: black;
	}

	md-icon.alert-icon {
		color: $orange-color;
	}
}

.highlighted-text {
	font-weight: bold;
	text-decoration: underline;
}

md-option.md-select-img-item {
	height: 150px;
}

remove-report-alert > div {
	margin: 8px;
}

.process-mtr-box-container {
	padding: 0px;

	md-input-container {
		margin: 5px;
	}
}

.process-mtr-box-container > div {
	margin-left: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 10px;
	padding-top: 10px;
}

.column-header {
	color: rgba(0, 0, 0, 0.54);
	font-size: 12px;
	font-weight: 700;
	white-space: nowrap;
}

.header-row {
	height: 28px;
	border-bottom: 3px solid rgba(0, 0, 0, 0.2);
}

.base-page-logo {
	margin: 10px;
	height: 42px;
	width: 256px;
}

.md-button.md-raised.md-primary.grid-last-column-refresh-button {
	float: right;
	height: 26px;
	min-height: unset;
	min-width: unset;
	padding: 0;
	width: 26px;

	.material-icons {
		font-size: 18px;
	}
}

.feature-toggle-id {
	width: 30%;
}

//Pagination fix
.md-table-pagination > md-select + span {
	margin-left: 32px;
}

//Grid fixes
.grid-content table.md-table.md-row-select td.md-cell:nth-child(n + 3):nth-last-child(n + 2),
.grid-content table.md-table.md-row-select th.md-column:nth-child(n + 3):nth-last-child(n + 2) {
	padding: 0 14px 0 0;
}

.grid-large-column {
	min-width: 180px;
}

.grid-column-text-wrap > span {
	white-space: normal;
}

//Button fixes
.md-button.form-button {
	margin-top: 12px;
}

.md-button.md-primary.md-raised md-progress-circular .md-inner .md-right .md-half-circle {
	border-color: white;
}

.md-button.big-with-border {
	width: 100%;
	border: 1px solid $primary-color;
}

/*-----------------------------------------------------------------------------*/
.small-spacing-autocomplete {
	md-autocomplete md-input-container {
		height: 34px;
	}

	md-autocomplete md-autocomplete-wrap md-progress-linear {
		bottom: 18px;
	}
}