//Main SCSS Configuration File. All css constants should be defined here in order to facilitate manteinance

$primary-color: #1e4960;
$primary-color-fade: rgba(9, 51, 73, 0.5);
$primary-lightest: #f2f6f9;

$secondary-color: #901663;
$secondary-color-fade: #5c2247;
$secondary-color-light: #bb73a1;

$strong-green: #218225;
$success-color: #4caf50;
$light-green: #94e297;

$light-blue: #bde4f6;
$neutral-light-2-color: #eaeaea;
$neutral-dark-color: #636363;

$blue-color: #039be5;
$blue-color-fade: rgba(0, 113, 166, 0.5);
$blue-color-high-fade: rgba(0, 58, 77, 0.07);

$light-grey: #dddddd;
$gray-color: #acacac;
$dark-grey: #212121;

$light-yellow: #fffaf2;
$alert-color: #ffc679;
$orange-color: #fb8c00;
$dark-orange-color: #a66000;

$color-warning-alert: #f35d09;
$color-warning-alert-dark: #cc500b;

$light-red: #e57979;
$danger-color: #a91616;
$color-negative-bright: #eb1207;
$negative-lightest: #fef2f2;

$attention-color: #f9f110;

$terciary-color: #de487c;
$quaternary-color: #2ef332;

$ius-natura-color: #003975;
